import { useQuery } from '@tanstack/react-query';
import { MobXProviderContext } from 'mobx-react';
import { ConversationModel } from 'Models/ConversationModel';
import { fetchMessages } from 'Modules/message/index.requests';
import { useContext, useRef, useEffect } from 'react';
import MessageStore from 'Stores/MessageStore';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { PollingConfig } from '../index.types';

const QUERY_KEY = 'messages-polling';

async function checkStaleMessages(
  messageStore: MessageStore,
  CurrentConversation: ConversationModel
): Promise<boolean> {
  if (!CurrentConversation?.id) {
    return false;
  }

  const response = await messageStore.loadConversationMessagesIfMissingGet(
    CurrentConversation?.id
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const hasStaleMessages = response?.data?.results.length > 0;

  if (hasStaleMessages) {
    return true;
  }

  return false;
}

/********************************************************************************
 * Hook for polling messages
 * @param pollingEnabled - Whether polling is enabled ***************************
 * @param intervalFunction - Function to generate the polling interval **********
 *******************************************************************************/
export const useMessagesPolling = ({
  pollingEnabled,
  intervalFunction,
}: PollingConfig) => {
  const {
    conversation: { CurrentConversation },
    message: messageStore,
  } = useContext<RootStoreProps>(MobXProviderContext);

  // Initialize the ref with an empty Set
  const initialFetchDoneRef = useRef<Set<string>>(new Set());

  // Ensure the ref always has a valid Set
  if (!initialFetchDoneRef.current) {
    initialFetchDoneRef.current = new Set<string>();
  }

  // Effect to clear the ref when the conversation changes
  useEffect(() => {
    if (!initialFetchDoneRef.current) {
      initialFetchDoneRef.current = new Set<string>();
    }

    return () => {
      if (CurrentConversation?.id && initialFetchDoneRef.current) {
        initialFetchDoneRef.current.delete(CurrentConversation.id);
      }
    };
  }, [CurrentConversation?.id]);

  /**
   * Query for polling messages
   * @returns The result of the polling query
   */
  return useQuery({
    queryKey: [QUERY_KEY, CurrentConversation?.id],
    queryFn: async () => {
      if (!CurrentConversation?.id) {
        return null;
      }

      const currentMessages = await messageStore.messageByConvStatusMap.get(
        CurrentConversation.id
      );

      try {
        const isInitialFetch = !initialFetchDoneRef.current.has(
          CurrentConversation.id
        );

        if (isInitialFetch) {
          const isStale = await checkStaleMessages(
            messageStore,
            CurrentConversation
          );

          if (isStale) {
            const result = await fetchMessages(
              CurrentConversation.id,
              messageStore
            );

            initialFetchDoneRef.current.add(CurrentConversation.id);
            return result;
          }
        }

        const result = await fetchMessages(
          CurrentConversation.id,
          messageStore
        );

        // Mark that we've done the initial fetch
        initialFetchDoneRef.current.add(CurrentConversation.id);
        return result;
      } catch (error) {
        console.error('Error fetching messages:', error);
        return currentMessages;
      }
    },
    enabled: pollingEnabled && !!CurrentConversation?.id,
    refetchInterval: intervalFunction,
    staleTime: 0,
    retry: false,
  });
};

export default useMessagesPolling;
