import { inject, observer } from 'mobx-react';
import React from 'react';
import { STORE_CONVERSATION, STORE_UI } from '../../constants/stores';
import { Channel } from '../../containers/Channel';
import { Styled } from './index.styles';
import { IGroupInfoProps } from './interface.d';

const GroupInfo = (props: IGroupInfoProps) => {
  const {
    conversation: { channelInfoDetails },
    ui: { groupModalOpen, openedRightSidebarsOrder },
  } = props;
  const match = { params: channelInfoDetails };

  return groupModalOpen ? (
    <Channel
      openGroupModal={groupModalOpen}
      match={match}
      {...props}
    />
  ) : (
    !['new', 'edit'].includes(channelInfoDetails?.channelMode) && (
      <Styled.GroupInfoStyled
        $zIndex={openedRightSidebarsOrder.get('sidebar-info')}
        id="group-info"
        className={`info-sidebar visible`}
        widescreen={4}
        computer={4}
        desktop={4}
        tablet={4}
        mobile={4}
      >
        <Channel
          from="group-info"
          match={match}
          {...props}
        />
      </Styled.GroupInfoStyled>
    )
  );
};

export default inject(STORE_CONVERSATION, STORE_UI)(observer(GroupInfo));
