import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import { AxiosResponseT, ResultsCollection } from 'Interfaces/axiosResponse';
import { MobXProviderContext } from 'mobx-react';
import { IConversationModel } from 'Models/ConversationModel';
import React from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import API from '~/api';
import { useHistoryConversationsStore } from './index.store';

export const QUERY_KEY_FETCH_HISTORY_CONVERSATIONS =
  'historyConversations.fetchHistoryConversation';

export const fetchHistoryConversations = async (
  conversationCurrentDate: string,
  conversationSubDate: string
): Promise<AxiosResponseT<ResultsCollection<IConversationModel>>> => {
  const historyResponse = await API.get<ResultsCollection<IConversationModel>>(
    API_ENDPOINTS.ConversationHistoryByDateRange(
      conversationCurrentDate,
      conversationSubDate
    )
  );

  return historyResponse;
};

export const useFetchHistoryConversations = (
  enabled: boolean,
  refetchInterval: number | (() => number) | false
) => {
  const {
    conversation: { loadConversationHistoryBasedOnDateSuccess },
    ui: { activeSidebarItem },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const { historyFetchParams } = useHistoryConversationsStore();

  return useQuery({
    queryKey: [
      QUERY_KEY_FETCH_HISTORY_CONVERSATIONS,
      enabled,
      refetchInterval,
      historyFetchParams?.conversationCurrentDate,
      historyFetchParams?.conversationSubDate,
    ],
    queryFn: async () => {
      if (!historyFetchParams) return;

      const historyResponse = await fetchHistoryConversations(
        historyFetchParams.conversationCurrentDate,
        historyFetchParams.conversationSubDate
      );

      const history =
        loadConversationHistoryBasedOnDateSuccess(historyResponse);

      useHistoryConversationsStore
        .getState()
        .updateHistoryConversations(history);

      return historyResponse;
    },
    enabled: enabled && activeSidebarItem === 'history',
    refetchInterval,
    staleTime: 0,
  });
};

export const useHistoryConversations = () => ({
  useFetchHistoryConversations,
});
