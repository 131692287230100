import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import { usePreferences } from 'Hooks/preferences/usePreferences';
import { MessagesGetRequest } from 'Interfaces/apiDtos';
import { MobXProviderContext } from 'mobx-react';
import { ConversationModel } from 'Models/ConversationModel';
import { IMessageModel } from 'Models/MessageModel';
import PersonModel from 'Models/PersonModel';
import { PollingConfig } from 'Providers/PollingProvider/index.types';
import { useContext } from 'react';
import MessageStore from 'Stores/MessageStore';
import { RootStoreProps } from 'Stores/RootStore.types';
import API from '~/api';

export const QUERY_KEY_FETCH_MESSAGE = 'message.fetchMessage';
export const QUERY_KEY_FETCH_MESSAGES = 'messages.fetchMessages';
/**
 * Fetch messages for a conversation
 * @param conversationId - The ID of the conversation
 * @param messageStore - The message store
 * @returns The messages
 */
export async function fetchMessages(
  conversationId: ConversationModel['id'],
  messageStore: MessageStore,
  showCallMessagesInChat: boolean | undefined
) {
  if (!conversationId) {
    throw new Error('No conversation ID provided');
  }

  const msgsGet: MessagesGetRequest = {
    Limit: 31,
    ShowCallMessagesInChat: showCallMessagesInChat,
    ShowDeleted: true,
    SortDirection: 'Descending',
  };

  return await messageStore.loadConversationMessages(conversationId, msgsGet);
}

/**
 * Fetch a message by conversationId and messageId
 * @param conversationId - The ID of the conversation
 * @param messageId - The ID of the message
 * @returns The message
 */
export const fetchMessage = async (
  conversationId: string,
  messageId: string
): Promise<IMessageModel | undefined> => {
  if (!conversationId || !messageId) {
    return undefined;
  }

  const { data } = await API.get<PersonModel>(
    API_ENDPOINTS.ConversationMessageById(conversationId, messageId)
  );

  return data;
};

/**
 * Fetch messages for a conversation
 * @param pollingEnabled - Whether polling is enabled
 * @param intervalFunction - The interval function
 * @returns The messages
 */
export const useFetchMessages = ({
  pollingEnabled,
  intervalFunction,
}: PollingConfig) => {
  const {
    conversation: { CurrentConversation },
    message: messageStore,
  } = useContext<RootStoreProps>(MobXProviderContext);

  const conversationId = CurrentConversation?.id;

  const { preferences } = usePreferences();

  return useQuery({
    queryKey: [QUERY_KEY_FETCH_MESSAGES, conversationId],
    queryFn: async () => fetchMessages(conversationId, messageStore, preferences?.showCallMessagesInChat),
    enabled: pollingEnabled && !!conversationId,
    refetchInterval: intervalFunction,
    staleTime: 0,
  });
};

/**
 * Fetch a message by conversationId and messageId
 * @param conversationId - The ID of the conversation
 * @param messageId - The ID of the message
 * @returns The message
 */
export const useFetchMessage = (conversationId: string, messageId: string) =>
  useQuery({
    queryKey: [QUERY_KEY_FETCH_MESSAGE, conversationId, messageId],
    queryFn: async () => fetchMessage(conversationId, messageId),
  });

export const useMessage = () => ({
  useFetchMessage,
  useFetchMessages
});
