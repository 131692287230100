import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from 'Constants/env';
import { MessagesGetRequest } from 'Interfaces/apiDtos';
import ConversationModel from 'Models/ConversationModel';
import { IMessageModel } from 'Models/MessageModel';
import PersonModel from 'Models/PersonModel';
import MessageStore from 'Stores/MessageStore';
import API from '~/api';

export const QUERY_KEY_FETCH_MESSAGE = 'message.fetchMessage';

/**
 * Fetch messages for a conversation
 * @param conversationId - The ID of the conversation
 * @param messageStore - The message store
 * @returns The messages
 */
export async function fetchMessages(
  conversationId: ConversationModel['id'],
  messageStore: MessageStore
) {
  const msgsGet: MessagesGetRequest = {
    Limit: 50,
    ShowDeleted: true,
  };

  const messages = await messageStore.loadConversationMessages(
    conversationId,
    msgsGet,
    true
  );

  return messages;
}

/**
 * Fetch a message by its ID
 * @param conversationId - The ID of the conversation
 * @param messageId - The ID of the message
 * @returns The message
 */
export const fetchMessage = async (
  conversationId: ConversationModel['id'],
  messageId: string
): Promise<IMessageModel | undefined> => {
  if (!conversationId || !messageId) {
    return undefined;
  }

  const { data } = await API.get<PersonModel>(
    API_ENDPOINTS.ConversationMessageById(conversationId, messageId)
  );

  return data;
};

export const useFetchMessage = (conversationId: string, messageId: string) =>
  useQuery({
    queryKey: [QUERY_KEY_FETCH_MESSAGE, conversationId, messageId],
    queryFn: async () => fetchMessage(conversationId, messageId),
  });

export const useFetchMessages = (
  conversationId: string,
  messageStore: MessageStore
) =>
  useQuery({
    queryKey: [QUERY_KEY_FETCH_MESSAGE, conversationId],
    queryFn: async () => fetchMessages(conversationId, messageStore),
  });

export const useMessage = () => ({
  useFetchMessage,
  useFetchMessages,
});
