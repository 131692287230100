import 'react-placeholder/lib/reactPlaceholder.css';
import 'semantic-ui-css/semantic.min.css';
import './app.less';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  AUTH0,
  AUTH0_CONNECTION,
  GOOGLE_CLIENT,
  IS_ELECTRON,
  LAUNCH_DARKLY_CLIENT,
  NODE_ENV,
  NODE_ENV_LOCAL_OR_DEVELOPMENT,
} from 'Constants/env';
import {
  STORE_CALENDAR,
  STORE_CALL_LOGS,
  STORE_CONFIG,
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_MESSAGE,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_PREFERENCE,
  STORE_PUSHER,
  STORE_ROUTER,
  STORE_SEARCH,
  STORE_UI,
} from 'Constants/stores';
import { FeatureFlagProvider } from 'Hocs/FeatureFlagProvider';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import * as localforage from 'localforage';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import moment from 'moment-timezone';
import PollingProvider from 'Providers/PollingProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router';
import { RootStore } from 'Stores/RootStore';
import {
  handleEventResponse,
  handleMarkRead,
  handleNavigateTo,
  handleOpenOnLogin,
  handleSavedCredentials,
  sendIpcRequestOpenOnLogin,
  sendIpcRequestSavedCredentials,
} from 'Utils/ipcRendererEvents';
import { configureAxiosInterceptors } from './api';
import { NotificationProvider } from './modules/notification/NotificationProvider';
import { routes } from './routes/index.routes';
import { Theme } from './styles/theme/Theme';
import { stopInitialStream } from './utils/streamUtils';

declare global {
  interface Window {
    localStream: any;
    localStream2: any;
    localStream3: any;
  }
}

if (document) {
  document.execCommand('defaultParagraphSeparator', false, 'p');
}
localforage.config({
  name: 'bvc-' + NODE_ENV,
});

// enable MobX strict mode
configure({ enforceActions: 'observed' });

// Configure `moment` locale (can be changed to 'es' or 'fr' for Spanish/French).
// See `moment-locales-webpack-plugin` webpack plugin config.
// See https://momentjs.com/docs/#/i18n/changing-locale/
moment.locale(['en', 'es', 'fr']);
if (window) {
  window['moment'] = moment;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60, // 1 hour
      staleTime: 1000 * 60 * 1, // 1 min
    },
  },
});

const rootStore = new RootStore(queryClient);
const rootStores = {
  [STORE_CONVERSATION]: rootStore.conversationStore,
  [STORE_MESSAGE]: rootStore.messageStore,
  [STORE_NOTIFICATION]: rootStore.notificationStore,
  [STORE_PERSON]: rootStore.personStore,
  [STORE_ROUTER]: rootStore.routerStore,
  [STORE_PARTICIPANT]: rootStore.participantStore,
  [STORE_PHONE_CALL]: rootStore.phoneStore,
  [STORE_PUSHER]: rootStore.pusherStore,
  [STORE_SEARCH]: rootStore.searchStore,
  [STORE_CONTACT]: rootStore.contactStore,
  [STORE_UI]: rootStore.uiStore,
  [STORE_PREFERENCE]: rootStore.preferenceStore,
  [STORE_CONFIG]: rootStore.configStore,
  [STORE_CALENDAR]: rootStore.calendarStore,
  [STORE_CALL_LOGS]: rootStore.callLogsStore,
};

stopInitialStream();

if (IS_ELECTRON) {
  // Register handlers for renderer incoming IPC messages
  handleNavigateTo(rootStore.routerStore);
  handleEventResponse(rootStore.calendarStore);
  handleMarkRead(rootStore.participantStore, rootStore.uiStore);
  handleOpenOnLogin(rootStore.preferenceStore);
  handleSavedCredentials(rootStore.personStore);

  // Send IPC requests to the main process to query required data (ex. open on login)
  sendIpcRequestOpenOnLogin();
  sendIpcRequestSavedCredentials();
}

const noop = () => {};
navigator.mediaSession?.setActionHandler('previoustrack', noop);
navigator.mediaSession?.setActionHandler('nexttrack', noop);
navigator.mediaSession?.setActionHandler('play', noop);
navigator.mediaSession?.setActionHandler('pause', noop);
navigator.mediaSession?.setActionHandler('stop', noop);

configureAxiosInterceptors(rootStore);

const providerConfig: Auth0ProviderOptions = {
  ...AUTH0,
  cacheLocation: 'localstorage',
  redirectUri: window.location.origin,
  useRefreshTokens: true,
};

if (AUTH0_CONNECTION) {
  providerConfig.connection = AUTH0_CONNECTION;
}

// Uncomment for explicit Bugsnag reporting
/*
if (window) {
  window.addEventListener('unhandledrejection', (pre) => {
    bugsnagClient.notify(pre.reason, (event) => {
      event.context = 'App Index';
      event.addMetadata('custom', {
        message: 'window has received an unhandledrejection event',
        function: 'Root',
      });
    });
  });

  window.addEventListener('error', (errEvt) => {
    bugsnagClient.notify(errEvt.error, (event) => {
      (event.context = 'App Index'),
        event.addMetadata('custom', {
          message: 'window has received an error event',
          function: 'Root',
        });
    });
  });
}
*/
const App = () => (
  <Provider {...rootStores}>
    <Auth0Provider {...providerConfig}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT}>
        {/* @ts-ignore */}
        <FeatureFlagProvider>
          <QueryClientProvider client={queryClient}>
            <PollingProvider>
              <Theme>
                <NotificationProvider>
                  <RouterProvider router={routes(rootStore)} />
                </NotificationProvider>
              </Theme>
              {NODE_ENV_LOCAL_OR_DEVELOPMENT && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </PollingProvider>
          </QueryClientProvider>
        </FeatureFlagProvider>
      </GoogleOAuthProvider>
    </Auth0Provider>
  </Provider>
);

const AppWithLDProvider = withLDProvider({
  clientSideID: LAUNCH_DARKLY_CLIENT,
  reactOptions: { useCamelCaseFlagKeys: false },
  options: { streaming: false },
})(App);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWithLDProvider />);
