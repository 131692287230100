import { CallLogs } from 'Components/CallLogs';
import { CallsDirectoryList } from 'Components/CallLogs/CallsDirectoryList';
import { CallLogsContextWrapper } from 'Components/CallLogs/contexts/CallLogsContextWrapper';
import { Channel } from 'Containers/Channel';
import EntityProfile from 'Containers/EntityProfile';
import React from 'react';
import { Navigate, RouteObject } from 'react-router';

const callsRoutes: RouteObject = {
  path: 'calls/*',
  handle: { title: 'Calls' },
  element: (
    <>
      <CallLogs />
      <EntityProfile />
    </>
  ),
  children: [
    {
      index: true,
      element: <Navigate to="all" replace />,
    },
    {
      path: 'missed',
      element: (
        <>
          <Channel />
          <CallLogsContextWrapper typeOfCallsId="missed">
            <CallsDirectoryList />
          </CallLogsContextWrapper>
        </>
      ),
      handle: { title: 'Missed' },
    },
    {
      path: 'incoming',
      element: (
        <>
          <Channel />
          <CallLogsContextWrapper typeOfCallsId="incoming">
            <CallsDirectoryList />
          </CallLogsContextWrapper>
        </>
      ),
      handle: { title: 'Incoming' },
    },
    {
      path: 'outgoing',
      element: (
        <>
          <Channel />
          <CallLogsContextWrapper typeOfCallsId="outgoing">
            <CallsDirectoryList />
          </CallLogsContextWrapper>
        </>
      ),
      handle: { title: 'Outgoing' },
    },
    {
      path: 'all',
      element: (
        <>
          <Channel />
          <CallLogsContextWrapper typeOfCallsId="all">
            <CallsDirectoryList />
          </CallLogsContextWrapper>
        </>
      ),
      handle: { title: 'All' },
    },
  ],
};

export default callsRoutes;
